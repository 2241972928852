import {captureException} from '@sentry/browser';
import {CSSDimension, Integer, Box, VStack, Spinner, SpinnerSize} from 'platform/foundation';

import {Component, ReactNode} from 'react';

import {isNil, isNotNil} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {EmptyStatus, EmptyStatusAction} from '../EmptyStatus/EmptyStatus';
import {ErrorStatus} from '../ErrorStatus/ErrorStatus';

interface DataStatusProps extends TestIdProps {
  spacing?: Integer;
  minHeight?: CSSDimension | Integer;
  grow?: number;
  isLoading?: boolean;
  isEmpty?: boolean;
  isError?: boolean;
  errorMessage?: string;
  emptyMessage?: string;
  emptySubheadline?: string;
  spinnerSize?: SpinnerSize;
  action?: EmptyStatusAction;
  children?: ReactNode;
}

interface DataStatusState {
  thrownError: Error | null;
}

export class DataStatus extends Component<DataStatusProps, DataStatusState> {
  constructor(props: DataStatusProps) {
    super(props);
    this.state = {
      thrownError: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return {thrownError: error};
  }

  componentDidCatch(error: Error) {
    captureException(error);
  }

  renderContent() {
    const {
      isError,
      isLoading,
      errorMessage,
      spinnerSize,
      emptyMessage,
      emptySubheadline,
      action,
      'data-testid': dataTestId,
    } = this.props;

    if (isNotNil(this.state.thrownError)) {
      return (
        <ErrorStatus
          headline={this.state.thrownError.name}
          subheadline={this.state.thrownError.message}
          data-testid={dataTestId}
        />
      );
    }

    if (isError) {
      return <ErrorStatus headline={errorMessage} data-testid={dataTestId} />;
    }

    if (isLoading) {
      return (
        <Spinner size={spinnerSize} data-testid={suffixTestId('dataStatus-spinner', this.props)} />
      );
    }

    return (
      <EmptyStatus
        headline={emptyMessage}
        subheadline={emptySubheadline}
        action={action}
        data-testid={dataTestId}
      />
    );
  }

  render() {
    const {minHeight, grow, spacing, isEmpty, isError, isLoading} = this.props;

    if (!isError && !isEmpty && !isLoading && isNil(this.state.thrownError)) {
      return this.props.children;
    }

    return (
      <VStack
        minHeight={minHeight}
        justify="center"
        align="center"
        grow={grow}
        data-testid={suffixTestId('dataStatus', this.props)}
      >
        <Box padding={spacing}>{this.renderContent()}</Box>
      </VStack>
    );
  }
}
